import React from "react"
import {
    Box,
    createStyles,
    Grid,
    LinearProgress,
    LinearProgressProps,
    Theme,
    Typography, withStyles
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    iconStyle: {
        height: "200px",
        width: "150px",
    },
    thStyle: {
        verticalAlign: "top"
    }
}))

function LinearProgressWithLabel(props: LinearProgressProps & { value: number } & { text: string }) {
    return (
        <Box display="flex" alignItems="center">
            <Box minWidth={90}>
                <Typography variant="body2" color="textPrimary">{props.text}</Typography>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textPrimary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
            border: "2px solid white",
            width: 200
        },
        colorPrimary: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.palette.secondary.main,
        },
    }),
)(LinearProgressWithLabel);

const Skills: React.FC<Props> = ({}) => {
    const {iconStyle, thStyle} = useStyles()
    return (
        <Grid container direction='row' justify='space-around'>
            <Grid item sm={12} md={8} container direction='column'>
                <Grid item>
                    <Typography variant='h4' color='secondary'>
                        Skills
                    </Typography>
                    <table style={{textAlign: "left", verticalAlign: "top", marginLeft: "-12px"}} cellSpacing="20px">
                        <tbody>
                        <Grid container direction="row" spacing={3}>
                            <Grid item className={thStyle} xs={12} sm={4}>
                                <Typography color='textPrimary'>Languages</Typography>
                            </Grid>
                            <Grid item className={thStyle} xs={12} sm={8}>
                                <BorderLinearProgress value={100} text="German"/>
                                <BorderLinearProgress value={100} text="English"/>
                                <BorderLinearProgress value={35} text="French"/>
                                <BorderLinearProgress value={35} text="Spanish"/>
                                <BorderLinearProgress value={30} text="Latin"/>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            <Grid item className={thStyle} xs={12} sm={4}>
                                <Typography color='textPrimary'>Coding</Typography>
                            </Grid>
                            <Grid item className={thStyle} xs={12} sm={8}>
                                <BorderLinearProgress value={100} text="Python"/>
                                <BorderLinearProgress value={100} text="TypeScript"/>
                                <BorderLinearProgress value={85} text="Java"/>
                                <BorderLinearProgress value={85} text="C"/>
                                <BorderLinearProgress value={70} text="Haskell"/>
                                <BorderLinearProgress value={50} text="Assembly"/>
                            </Grid>
                        </Grid>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
            <Grid item sm={12} md={4} container direction='column' alignItems='center'>
                <Grid item>
                    <AssignmentTurnedInIcon className={iconStyle} color='primary'/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Skills
