import React from "react"
import {
    Theme,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Education from "./cvChapters/Education";
import WorkExperience from "./cvChapters/WorkExperience";
import Skills from "./cvChapters/Skills";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import {Fade} from "react-awesome-reveal";

// import SwiperCore, {
//     Pagination, Autoplay
// } from 'swiper/core';
import Footer from "./Footer";
import CvCardWrapper from "./cvChapters/CvCardWrapper";
import Volunteering from "./cvChapters/Volunteering";
import CliffDiving from "./cvChapters/CliffDiving";

// SwiperCore.use([Pagination, Autoplay]);

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    rootBox: {},
}))


const About: React.FC<Props> = ({}) => {
    const {rootBox} = useStyles()
    const AdobeText = (
        <Typography color='textPrimary'>
            In my role as Data Analyst, I have been applying my skills in Python, JavaScript, HTML, and
            PowerBI to support the internal operations and strategy of the company.
            Some of my achievements include:<br/>
            - Working with pandas to manipulate and analyze data from different sources and formats.<br/>
            - Creating interactive and engaging data visualizations using d3.js and custom PowerBI
            visuals.<br/>
            - Introducing a new deployment pipeline with Jenkins on a Linux VM to automate and streamline
            the delivery of web applications and reports.<br/>
            - Automating Excel reporting using Python scripts to save time and improve accuracy.<br/>
            I enjoy working with data and finding insights that can help the company achieve its goals and
            optimize its performance.
            I am always eager to learn new technologies and tools that can enhance my data analysis
            capabilities.
        </Typography>
    )
    const AdobeCompany = (
        <Typography color='primary'>
            Data Analyst - Adobe Consulting Services EMEA <br/>
            06/2021 - today
        </Typography>
    )
    const IntradaCompany = (
        <Typography color='primary'>
            Working student at Intrada GmbH<br/> 01/2021 - 06/2021
        </Typography>
    )
    const IntradaText = (
        <Typography color='textPrimary'>
            As a frontend developer at a contact tracing startup, I was part of a small team of a few
            developers who built a web application to help track and prevent the spread of COVID-19.
            My responsibilities included: <br/>
            - Designing and building the web frontend with React.js, HTML, CSS, and Bootstrap<br/>
            - Participating in major decisions about the direction and design of the product<br/>
            - Managing part of the workflow using Git and Agile methodologies<br/>
            - Learning new skills and technologies such as JavaScript, JQuery, and Python<br/>
            I gained valuable experience in frontend development with modern tools and frameworks, as well
            as some exposure to backend development in Python.
            I also contributed to a meaningful project that aimed to improve public health and safety during
            the pandemic.
        </Typography>
    )


    return (<div className={rootBox}>
        <Typography variant='h3' color='primary' style={{fontWeight: 600, textAlign: "center"}}>
            RESUME
        </Typography>
        <Fade fraction={0.5} onVisibilityChange={() => {
        }}>
            <CvCardWrapper>
                <Education/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <CvCardWrapper>
                <WorkExperience Text={AdobeText} Company={AdobeCompany}/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <CvCardWrapper>
                <WorkExperience Text={IntradaText} Company={IntradaCompany}/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <CvCardWrapper>
                <Skills/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <CvCardWrapper>
                <Volunteering/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <CvCardWrapper>
                <CliffDiving/>
            </CvCardWrapper>
        </Fade>
        <Fade fraction={0.5}>
            <Footer notFixed/>
        </Fade>

    </div>)
}

export default About
