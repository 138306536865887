import React from "react"
import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: "auto",
        width: "60%",
        marginTop: "100px",
        // padding: "100px 300px 0px 300px",
        minHeight: "80vh",
        [theme.breakpoints.down('sm')]: {
            padding: "60px 0px 0px 0px",
            width: "95%",
            marginTop: "0px"
        },
    }
}))

const CvCardWrapper: React.FC<Props> = ({children}) => {
    const {root} = useStyles()
    return (
        <div className={root}>
            {children}
        </div>

    )
}

export default CvCardWrapper
