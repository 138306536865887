import {createMuiTheme } from '@material-ui/core/styles';
import "@fontsource/roboto-mono";
export const primaryColor = '#ff9800'
export const secondaryColor = '#6c24d4'
export const textSecondaryColor = '#ffffff'
export const textPrimaryColor = '#dbdbdb'

export const theme = createMuiTheme({
    palette: {
        background: {
            default: '#262626' //you have to change this in the index.html
        },
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor
        },
        text:{
            primary: textPrimaryColor,
            secondary: textSecondaryColor,
        }
    },
    typography:{
      fontFamily: [
          'Roboto Mono',
          'Roboto',
          'Arial',
          'sans-serif'
      ].join(',')
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        }
    },
    overrides:{
        MuiIconButton:{
            root:{
                "&:hover": {
                    color: secondaryColor
                },
                borderRadius: "3px",
                transition: "0.3s",
                color: textSecondaryColor
            },
            colorPrimary: {
            },
        },
        MuiIcon:{
            colorPrimary:{
                color: primaryColor
            },
            colorSecondary: {
                color: secondaryColor
            }
        },
        MuiLink:{
            underlineNone:{
                "&:hover": {
                    color: secondaryColor,
                    cursor: "pointer"
                },
                color: textSecondaryColor,
                transition: "0.3s",
            }
        },
        MuiPaper:{
            root:{

            },
            elevation24:{
                backgroundColor: "#000000",
                border: "2px solid gray"
            }
        }
    }
});
{

}
