import React, {useState} from "react"
import {Grid, IconButton, makeStyles, TextField, Theme, useMediaQuery} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import bG from "../resources/BackgroundImage.webp"
import Footer from "./Footer";
import cmd from "../resources/promptIcon.webp"
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typewriter from "typewriter-effect";
import {theme} from "../config/theme";


interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    contentRoot: {
        display: "block",
        minHeight: "100vh",
        textAlign: "left",
        backgroundImage: `url(${bG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "cover",
            backgroundPosition: "center top",
        }
    },
    promptWindow: {
        margin: "auto",
        width: "50%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        maxWidth: "600px",
        background: "rgba(0, 0, 0, 0.85)",
        minHeight: "370px",
        maxHeight: "370px",
        position: "relative",
    },
    textContainer: {
        overflowY: "scroll",
        height: "335px",
    },
    topBar: {
        height: "30px",
        padding: "5px 5px 0px 5px",
        backgroundColor: "white"
    },
    imgStyle: {
        width: "25px",
        height: "20px",
        display: "inline-block"
    },
    iconStyle: {
        color: "black",
        height: "30px",
    },
    bottomText: {
        bottom: 0
    },
    startIcon: {
        // center
        position: "absolute",
        left: "50%",
        top: "50%",
    }
}))

const Home: React.FC<Props> = ({}) => {
    const {contentRoot, promptWindow, topBar, imgStyle, iconStyle, textContainer, startIcon} = useStyles()
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [input, setInput] = useState<string>("");
    const [text, setText] = useState<string[]>([]);
    const mobileViewOn = useMediaQuery(theme.breakpoints.down("sm"));
    let promptUser = mobileViewOn ? "visitor:~$" : "visitor@tim-hajek.de:~$ ";
    const [promptHistory, setPromptHistory] = useState<string[]>([]);
    const [promptHistoryIndex, setPromptHistoryIndex] = useState<number>(0);
    const [closePrompt, setClosePrompt] = useState<boolean>(false);
    const infoText = ["I am passionate about using data to solve some of the complex problems that the world throws at us, both in my personal and professional life.",
        "I love working with highly motivated people and aim to make a real impact with what we do.",
        "My interests primarily revolve around economics, business, and data science.",
        "During my studies, I developed a strong foundation in machine learning, economics, and business.",
        "Additionally, I worked as a data analyst for Adobe Consulting Services for two years while pursuing my Bachelor's degree.",
        "I enjoy working for meaningful projects. During my studies I helped developing the Website of Mindsupport e.V, a platform that offers support for people with mild mental health difficulties.",
        "In addition, I regularly volunteer my time as a Tech-Coach for Code+Design, where I introduce children and teenagers to modern programming languages like Python,",
        "helping them create their first programs and mini-games to foster their interest and passion for technology and innovation, sometimes more and sometimes less successfully ;).",
        "I am always seeking new challenges and learning opportunities to further improve my skills and grow professionally.",
        "If you would like to learn more about me or work with me, please do not hesitate to contact me via LinkedIn or email at mail@tim-hajek.de.",
        "I look forward to hearing from you!"]
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value)
    }
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowUp") {
            console.log(promptHistory)
            if (promptHistory.length > 0) {
                if (promptHistoryIndex > 0) {
                    console.log(promptHistoryIndex)
                    setPromptHistoryIndex(promptHistoryIndex - 1)
                    setInput(promptHistory[promptHistoryIndex - 1])
                }
            }
        } else if (event.key === "ArrowDown") {
            if (promptHistory.length > 0) {
                if (promptHistoryIndex < promptHistory.length) {
                    setPromptHistoryIndex(promptHistoryIndex + 1)
                    if (promptHistoryIndex === promptHistory.length - 1) {
                        setInput("")
                    } else {
                        setInput(promptHistory[promptHistoryIndex + 1])
                    }
                }
            }
        } else if (event.key === "Enter") {
            let textArray = text;
            textArray.push(promptUser + " " + input);
            switch (input.toLowerCase()) {
                case "tim -h":
                case "tim -help":
                    textArray.push("Available commands:")
                    textArray.push("tim -h, tim -help: Show this help")
                    textArray.push("tim -i, tim -info: Show more info")
                    textArray.push("tim -c, tim -contact: Contact me")
                    textArray.push("clear: Clear the screen")
                    break;
                case "tim -info":
                case "tim -i":
                    textArray = textArray.concat(infoText)
                    break;
                case "tim -c":
                case "tim -contact":
                    window.open('mailto:mail@tim-hajek.de')
                    break;
                case "clear":
                    textArray = []
                    break;
                case "systemctl stop tim":
                    textArray.push("Stopping service \"tim\"...")
                    //wait 2 seconds
                    setTimeout(() => {
                            // close the tab
                            closePromptWindow()
                        }
                        , 2000)
                    break;
                default:
                    textArray.push("Command not found. Type \"tim -h\" for help.")
            }
            setInput("")
            setText(textArray)
            if (input !== "") {
                setPromptHistory([...promptHistory, input])
                setPromptHistoryIndex(prev => prev + 1)
            }
        }
    }
    const closePromptWindow = () => {
        setClosePrompt(true)
        //reset all states
        setShowHelp(true)
        setInput("")
        setText([])
        setPromptHistory([])
        setPromptHistoryIndex(0)
    }

    return (
        <div className={contentRoot}>
            <div style={{height: "100px"}}/>
            <div className={promptWindow} style={{display: closePrompt ? "none" : ""}}>
                <div className={topBar}>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <img src={cmd} alt="Command Prompt Icon" className={imgStyle}/>
                            <Typography style={{display: "inline-block", paddingLeft: "10px"}}>Command
                                Prompt</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={iconStyle}
                                onClick={() => setClosePrompt(true)}
                            >
                                <MinimizeIcon/>
                            </IconButton>
                            <IconButton className={iconStyle}>
                                <CheckBoxOutlineBlankIcon/>
                            </IconButton>
                            <IconButton
                                className={iconStyle}
                                onClick={() => closePromptWindow()}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <div className={textContainer}>
                    <div style={{display: showHelp ? "initial" : "none"}}>
                        {text.map((value, index) => {
                            return <Typography color="textPrimary" key={index}>{value}</Typography>
                        })}
                    </div>
                    <div style={{position: showHelp ? "static" : "absolute"}}>
                        <Typography
                            color="textPrimary"
                            style={{
                                display: "inline-block",
                                marginRight: "5px"
                            }}
                        >
                            {promptUser}
                        </Typography>
                        <TextField
                            id="standard-basic"
                            value={input}
                            style={{display: showHelp ? "inline-block" : "none"}}
                            onChange={handleInput}
                            onKeyDown={handleKeyPress}
                            autoComplete={"off"}
                        />
                        <Typography color="textPrimary"
                                    style={{display: showHelp ? "none" : "inline-block"}}>
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString('systemctl start tim')
                                        .pauseFor(250)
                                        .start()
                                        .pauseFor(250)
                                        .callFunction(() => {
                                            setShowHelp(true)
                                            setText([promptUser + "systemctl start tim", "Hi, my name is Tim Hajek",
                                                "I am a Munich based computer " +
                                                "science student, data analyst and web developer.",
                                                "Type \"tim -h\" for a list of commands or take a look around"])
                                        });
                                }}/>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={startIcon} style={{display: closePrompt ? "" : "none"}}>

                <IconButton
                    onClick={() => setClosePrompt(false)}
                > <img src={cmd} alt="Command Prompt Icon" className={imgStyle}/>
                </IconButton>
            </div>
            <Footer/>
        </div>
    )
}
export default Home
