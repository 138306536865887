/**This files defines all the endpoints to be used in this project**/



//change this to actual root when deploying to production
const apiRoot = 'https://log.tim-hajek.de/';


/**----------log user endpoints-----------**/
export const LOG_VISIT_ENDPOINT = apiRoot + 'log'
export const LOG_REFERRAL_ENDPOINT = apiRoot + 'referral'
