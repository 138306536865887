import React, {useEffect} from "react"
import {useHistory} from "react-router-dom";


interface Props {
    setPage: (page: string) => void
}

const Contact: React.FC<Props> = ({setPage}) => {
    const history = useHistory()

    useEffect(() => {
        window.open('mailto:mail@tim-hajek.de')
        setPage("home")
        history.push("/home")
    }, [])

    return (<div/>)
}

export default Contact
