import {uuidv4} from "./utils";

/** Gets uuid from localstorage, if there is no uuid it sets one */
export const UuidFromLocalStorage = () => {
    if (localStorage.getItem("UUID")=== null) {
        const UUID = uuidv4()
        localStorage.setItem("UUID", UUID);
        return UUID;
    }
    const uuid = localStorage.getItem("UUID");
    return uuid||"none";
}
