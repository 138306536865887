import React from "react";
import Footer from "./Footer";
import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router-dom";

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    rootDiv: {
        margin: "30px 20px 0 20px",
        [theme.breakpoints.up('md')]: {
            margin: "30px 150px 0 150px"
        },
        color: "white"
    },
}))

const Imprint: React.FC<Props> = ({}) => {
    const {rootDiv} = useStyles()
    const history = useHistory()

    return (
        <div className={rootDiv}>
            <Typography variant="body1" color="textSecondary" align="center">
                <Link color="inherit" onClick={()=> history.push("")}>
                    go back
                </Link>
            </Typography>
            <Typography variant="h4">
                Angaben gemäß § 5 TMG
            </Typography>
            <Typography variant="body1">
                Timon Hajek <br/>
                85375 Neufahrn bei Freising <br/>
            </Typography>
            <br/>
            <Typography variant="h4">
                Kontakt
            </Typography>
            <Typography variant="body1">
                E-Mail: mail@tim-hajek.de
            </Typography>
            <br/>
            <Typography variant="h4">
                Haftung für Inhalte
            </Typography>
            <Typography variant="body1">
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                allgemeinen
                Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die
                auf
                eine rechtswidrige Tätigkeit hinweisen.

                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
                bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                einer
                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese
                Inhalte umgehend entfernen.
            </Typography>
            <br/>
            <Typography variant="h5">
                Haftung für Links
            </Typography>
            <Typography variant="body1">
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                verlinkten
                Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                zum
                Zeitpunkt der Verlinkung nicht erkennbar.

                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
            </Typography>
            <br/>
            <Typography variant="h5">
                Urheberrecht
            </Typography>
            <Typography variant="body1">
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.

                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter
                beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von
                Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </Typography>
            <Footer notFixed />
        </div>)
}
export default Imprint
