import Header from "./mainPageComponents/Header";
import React, {useEffect, useState} from "react";
import Home from "./mainPageComponents/Home";
import About from "./mainPageComponents/About";
import Contact from "./mainPageComponents/Contact";
import {Switch, Route, Redirect} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from "./config/theme";
import {logReferral, logUser} from "./utils/api";
import {UuidFromLocalStorage} from "./utils/localStorage";
import {useHistory} from 'react-router-dom'
import Imprint from "./mainPageComponents/Imprint";


export const subSites = ["home", "about", "contact"]


function log(path: string) {
    logUser({
            path: path,
            uuid: UuidFromLocalStorage(),
            vh: window.innerHeight,
            vw: window.innerWidth
        },
    ).catch((error) => console.warn(error))
    return null;
}

function referral(code: string) {
    logReferral(code).catch((error) => console.warn(error))
    return null;
}

function getPageName(path: string | null) {
    if (path && path.includes("ee/")) {
        return path.substring(3)
    }
    if (path && subSites.includes(path)) {
        return path
    } else {
        return "home"
    }
}

const App: React.FC = ({}) => {
    const history = useHistory()
    const [page, setPage] = useState<string>(getPageName(history.location.pathname.substring(1)))

    useEffect(() => {
        if (history.location.pathname !== "/home") {
            log(history.location.pathname)
        }
        return history.listen((location) => {
            if (history.location.pathname !== "/home") {
                log(history.location.pathname)
            }
        })
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Header setPage={setPage} page={page}/>
            <Switch>
                <Route path="/" exact>
                    <Home/>
                </Route>
                {/*<Route path="/projects" exact>*/}
                {/*    <Projects/>*/}
                {/*</Route>*/}
                <Route path="/about" exact>
                    <About/>
                </Route>
                <Route path="/contact" exact>
                    <Contact setPage={setPage}/>
                </Route>
                <Route path='/linkedin' component={() => {
                    window.location.href = 'https://www.linkedin.com/in/hajek-tim';
                    return null;
                }}/>
                <Route path="/imprint" exact>
                    <Imprint/>
                </Route>
                <Route path="/r/">
                    {() => {
                        referral(history.location.pathname.substring(2))
                        history.push("/")
                    }}
                </Route>
                <Redirect from='/' to={"/"}/>
            </Switch>
        </ThemeProvider>
    )
}

export default App;
