import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {useHistory} from "react-router-dom";

interface Props {
    notFixed?: boolean
}

const title = "www.tim-hajek.de"


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://tim-hajek.de/">
                Tim Hajek
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function ContactFooter() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Contact: '}
            <Link color="inherit" href="mailto:mail@tim-hajek.de">
                mail@tim-hajek.de.
            </Link>
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    footer: {
        [theme.breakpoints.up('sm')]: {
            position: "absolute"
        },
        left: 0,
        bottom: 0,
        right: 0,
        marginBottom: "30px",
        marginTop: "50px"
    },
    notFixedStyle: {
        marginBottom: "60px",
        marginTop: "20px"
    }
}));

export default function Footer(props: Props) {
    const classes = useStyles();
    const history = useHistory()
    return (
        <footer className={props.notFixed ? classes.notFixedStyle : classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom color="textPrimary">
                    {title}
                </Typography>
                <Copyright/>
                <ContactFooter/>
                <Typography variant="body2" color="textSecondary" align="center">
                    <Link color="inherit" onClick={() => history.push("/imprint")}>
                        Imprint
                    </Link>
                </Typography>
            </Container>
        </footer>
    );
}
