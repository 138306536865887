import React from "react"
import {Avatar, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import bb from "../../resources/Bewerbungsbildv4.webp"

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    imgStyle: {
        height: "200px",
        width: "150px",
        borderRadius: "40px",
        zIndex: -10
    },
    thStyle: {
        verticalAlign: "top"
    },
}))

const Education: React.FC<Props> = ({}) => {
    const {imgStyle, thStyle} = useStyles()
    return (
        <Grid container direction='row' justify='space-around' >
            <Grid item md={8}>
                <Typography variant='h4' color='secondary'>
                    Education
                </Typography>
                <table style={{textAlign: "left"}} cellSpacing="20px">
                    <tbody>
                    <tr>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>10/2019 - now</Typography>
                        </th>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>Computer Science TU Munich</Typography>
                        </th>
                    </tr>
                    <tr>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>10/2018 – 10/2019</Typography></th>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>Gap Year in Australia/South America</Typography>
                        </th>
                    </tr>
                    <tr>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>09/2010 – 06/2018</Typography>
                        </th>
                        <th className={thStyle}>
                            <Typography color='textPrimary'>Abitur (equivalent to GCE A-levels), Grade: 1,9</Typography>
                        </th>
                    </tr>
                    </tbody>
                </table>
            </Grid>
            <Grid item md={4}>
                <Avatar src={bb} alt='Picture of my beautiful face ;)' className={imgStyle}
                        variant="rounded"/>
            </Grid>
        </Grid>
    )
}

export default Education
