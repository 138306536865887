import React from "react"
import {Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PoolIcon from '@material-ui/icons/Pool';
import VideoPlayer from "../../utils/VideoPlayer";
import jump from "../../resources/jump.webm"
import jumpFallback from "../../resources/jump.mp4"

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    iconStyle: {
        height: "200px",
        width: "150px",
    },
    videoStyle: {
        maxHeight: "400px",
    }
}))

const CliffDiving: React.FC<Props> = ({}) => {
    const {iconStyle, videoStyle} = useStyles()
    // boolean that indicates if webm is supported
    const webmSupported = document.createElement('video').canPlayType('video/webm; codecs="vp8, vorbis"')
    //if webm is supported, use webm, else use mp4
    const videoSrc = webmSupported ? jump : jumpFallback

    return (
        <Grid container direction='row' justify='space-around'>
            <Grid item sm={12} md={8} container direction='column'>
                <Grid item>
                    <div>
                        <VideoPlayer videoSrc={videoSrc} height="500px" width="350px"/>
                    </div>
                </Grid>
            </Grid>
            <Grid item sm={12} md={4} container direction='column' alignItems='center'>
                <Grid item>
                    <Typography
                        variant='h6'
                        color='primary'
                        style={{
                        fontWeight: 600,
                        textAlign: "center",
                        }}
                    >
                        And that's what I do for fun ;) <br/>
                    </Typography>
                </Grid>
                <Grid item>
                    <PoolIcon className={iconStyle} color='primary'/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CliffDiving
