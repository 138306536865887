import React, {useState, useRef} from 'react';

interface Props {
    videoSrc: string
    height?: string
    width?: string
}

const VideoPlayer: React.FC<Props> = ({
                                          videoSrc,
                                          height = "100%",
                                          width = "100%"
                                      }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef(null);
    const type = videoSrc.includes("mp4") ? "video/mp4" : "video/webm";

    const togglePlay = () => {
        if (isPlaying) {
            // @ts-ignore
            videoRef.current.pause();
        } else {
            // @ts-ignore
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgress = () => {
        // @ts-ignore
        const duration = videoRef.current.duration;
        // @ts-ignore
        const currentTime = videoRef.current.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };
    return (
        <div>
            <video
                onTimeUpdate={handleProgress}
                ref={videoRef}
                width={width}
                height={height}
                controls
                autoPlay
                muted
                loop
                style={{borderRadius: "20px",
                    objectFit: "cover",
                }}
            >
                <source src={videoSrc}
                        type={type}/>
            </video>
        </div>
    )
}

export default VideoPlayer;
