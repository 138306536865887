import React from "react"
import {Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WorkIcon from '@material-ui/icons/Work';

interface Props {
    Company: JSX.Element
    Text: JSX.Element
}

const useStyles = makeStyles((theme: Theme) => ({
    iconStyle: {
        height: "200px",
        width: "150px",
    },
    thStyle: {
        verticalAlign: "top"
    }
}))

const WorkExperience: React.FC<Props> = ({
                                             Company,
                                             Text
                                         }) => {
    const {iconStyle} = useStyles()
    return (
        <Grid container direction='row' justify='space-around'>
            <Grid item sm={12} md={8} container direction='column'>
                <Grid item>
                    <Typography variant='h4' color='secondary' style={{marginBottom: "20px"}}>
                        Work Experience
                    </Typography>
                    {Company}
                    {Text}
                </Grid>
            </Grid>
            <Grid item sm={12} md={4} container direction='column' alignItems='center'>
                <Grid item>
                    <WorkIcon className={iconStyle} color='primary'/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default WorkExperience
