import React from "react"
import {Grid, Link, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FavoriteIcon from '@material-ui/icons/Favorite';

interface Props {
}

const useStyles = makeStyles((theme: Theme) => ({
    iconStyle: {
        height: "200px",
        width: "150px",
    },
    thStyle: {
        verticalAlign: "top"
    }
}))

const Volunteering: React.FC<Props> = ({}) => {
    const {iconStyle, thStyle} = useStyles()
    return (
        <Grid container direction='row' justify='space-around'>
            <Grid item sm={12} md={8} container direction='column'>
                <Grid item>
                    <Typography variant='h4' color='secondary' style={{marginBottom: "20px"}}>
                        Volunteering
                    </Typography>
                    <Typography color='primary'>
                        Full Stack Developer for&nbsp;
                        <Link href='https://www.mindsupport.eu/'>
                            Mind Support e.V.</Link> <br/>
                        08/2021 - 08/2022
                    </Typography>
                    <Typography color='textPrimary'>
                        During my studies, I had the opportunity to work with Mind Support e.V., a non-profit
                        organization
                        that provides support for individuals with mild mental health difficulties. I contributed to the
                        development of their website, helping to make it more accessible and user-friendly
                    </Typography>
                    <br/>
                    <Typography color='primary'>
                        Tech-Coach for&nbsp;
                        <Link href='https://code.design/'>
                            code+design
                        </Link> <br/>
                        10/2022 - today
                    </Typography>
                    <Typography color='textPrimary'>
                        As a Tech-Coach for Code+Design, I regularly volunteer my time to introduce children and
                        teenagers to the fundamentals of modern programming languages such as Python. In this role, I
                        assist them in creating their first programs and mini-games, igniting their interest and passion
                        for technology and innovation
                    </Typography>

                </Grid>
            </Grid>
            <Grid item sm={12} md={4} container direction='column' alignItems='center'>
                <Grid item>
                    <FavoriteIcon className={iconStyle} color='primary'/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Volunteering
