import axios from "axios";
import {LOG_REFERRAL_ENDPOINT, LOG_VISIT_ENDPOINT} from "../config/endpoints";
import {LogDataDTO} from "../config/serializables";


/**API post to log user visit for the dashboard
 * @param data - User data
 * @return - Nothing
 **/
export const logUser = (data: LogDataDTO) => {
    const client = axios.create();
    return client.post(LOG_VISIT_ENDPOINT, {data:data})
}

/**API patch to log a referral visit
 * @param code - referral code (like: tim-hajek.de/r/linkedin -> code: linkedin)
 * @return - Nothing
 **/
export const logReferral = (code: string) => {
    const client = axios.create();
    return client.patch(LOG_REFERRAL_ENDPOINT+code)
}
