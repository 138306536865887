import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {ExpandMore,ExpandLess, LinkedIn} from "@material-ui/icons";
import {ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, useMediaQuery} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Typewriter from 'typewriter-effect';
import {useHistory} from "react-router-dom";
import {subSites} from "../App";
import {theme} from "../config/theme";


interface Props {
    page: string,
    setPage: (page:string)=>void
}


const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: "0px 30px 0px 30px",
        [theme.breakpoints.down("xs")]:{
            padding: "0px 60px 0px 10px"
        },
        position: "sticky",
        top: 0,
        backgroundImage: "linear-gradient(to bottom, rgba(38, 38, 38, 1), rgba(38, 38, 38, 1), rgba(38, 38, 38, 0))",
        zIndex: 9999
    },
    toolbarTitle: {
        marginRight: "15px",
        [theme.breakpoints.down("xs")]:{
            padding: "0px 60px 0px 10px"
        },
    },
    pageTitle:{
        flex: 1,
        marginRight: "15px",
        [theme.breakpoints.down("xs")]:{
            fontSize: "1.3rem"
        },
    }
}));

export default function Header(props: Props) {
    const classes = useStyles();
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const mobileViewOn = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const mobileMenu = (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                endIcon={open?<ExpandLess style={{fontSize: "50px"}}/>:<ExpandMore style={{fontSize: "50px"}}/>}
            >
                <Typography variant='h5'>
                    MENU
                </Typography>
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{position: "sticky"}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper elevation={24}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {subSites.map((site) =>
                                        <MenuItem onClick={handleClose} key={site}>
                                            <Link underline='none' onClick={() => {
                                                props.setPage(site)
                                                history.push('/' + site)
                                            }}>
                                                <Typography
                                                    component="h2"
                                                    variant="h5"
                                                    color="secondary"
                                                    className={classes.toolbarTitle}
                                                >
                                                    {'.' + site + '()'}
                                                </Typography>
                                            </Link>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>)
    const desktopMenu = (
        subSites.map((site) =>
            <Link underline='none' onClick={() => {
                props.setPage(site)
                history.push('/' + site)
            }}>
                <Typography
                    component="h2"
                    variant="h5"
                    align="right"
                    className={classes.toolbarTitle}
                >
                    {'.' + site + '()'}
                </Typography>
            </Link>
        )
    )


    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    size="small"
                    href="https://www.linkedin.com/in/hajek-tim"
                >
                    <LinkedIn fontSize='large'/>
                </IconButton>
                <Typography
                    component="h2"
                    variant="h4"
                    color="primary"
                    align="left"
                    className={classes.pageTitle}
                >
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.typeString('.' + props.page + '()')
                                .start();
                        }} key={props.page}/>
                </Typography>
                {mobileViewOn?mobileMenu:desktopMenu}
            </Toolbar>
        </React.Fragment>
    );
}

